import React from 'react'
import HeroBanner from '../component/Common Banner Component/HeroBanner'
import TextContent from '../component/TextContent/TextContent'
import Footer from '../component/Footer/Footer'
import { Helmet } from 'react-helmet'
import PlainCenterText from '../component/PlainCenterText/PlainCenterText'


const cardSectionData = [
  {
    title:"CONSULTATION",
    content:"",
    img:"/images/icons/about-cards-icons-3.png"
  },
  {
    title:"SIZE GUIDE",
    content:"",
    img:"/images/icons/about-cards-icons-2.png"
  },
  {
    title:"Custom Designs",
    content:"",
    img:"/images/icons/about-cards-icons-1.png"
  },
 
 
  {
    title:"Luxury Presentation Boxes",
    content:"",
    img:"/images/icons/about-cards-icons-4.png"
  },
  {
    title:"Wholesale Pricing",
    content:"",
    img:"/images/icons/about-cards-icons-5.png"
  },
  {
    title:"<span class='font-para'>3</span>rd-Party Appraisals",
    content:"",
    img:"/images/icons/about-cards-icons-6.png"
  },
  
]


function About() {
  return (
    <>
    <Helmet>
          <title> 
          Custom Wedding & Anniversary Bands | Private Consultations for Diamond Rings
          </title>
          <meta 
          name='description' 
          content="Explore custom wedding and anniversary bands with expert guidance. Create a unique anniversary diamond piece that reflects your style and budget." 
          />
          <link rel="canonical" href="https://www.leedaviddiamonds.com/about" />
          
        </Helmet>
        
      
      <HeroBanner 
        subHeading=""
        Heading="ABOUT"
        nextLineSpanHeading=""
        Paragraph=""
        bgImg="/images/creatives/about-hero-bg.jpg"
        btn={false}
        sideLinks={false}
      />

      <TextContent
         subHeading=""
         heading= "WHAT I DO"
         content={"As a private jeweller with direct access to diamond suppliers, we offer clients significant savings compared to traditional retail stores. At Lee David Diamonds, you can be confident in receiving the best possible price.\n We provide outstanding value on both natural and lab-grown diamonds, without compromising on quality. Whether you have a clear vision or need assistance in the design process, we provide a personalized experience that transforms your dream design into a reality, often in under two weeks! \nLocated in the heart of Halton Region, we proudly serve clients in from Milton, Oakville, Burlington, Georgetown, Mississauga, Orangeville, Guelph, Cambridge, Kitchener, and Hamilton and surrounding area."}
        btn={true}
         btnText="BOOK A CONSULTATION"
         btnRefTel={true}
         subHeadingLine={false}
         shiftRow={true}
         img="/images/creatives/about-content-img.png"
         reviewContent={false}
         btnImg={false}
         />

        <div style={{backgroundColor:"#F5F3EF"}}>
          <PlainCenterText
            subHeading=""
            heading="OUR SERVICES OFFER A COMPLETE EXPERIENCE"
            paragraph="From expert diamond selection to custom jewellery design, our services are tailored to meet your unique preferences and ensure a seamless, luxurious experience."
            fontDark={true}
            cardSection={cardSectionData}
          />
        </div>

      <Footer
        newsletterSection={false}
      />
    </>
  )
}

export default About