import React from 'react'
import Footer from '../component/Footer/Footer'
import FaqSection from '../component/FaqSection/FaqSection'
import { Helmet } from 'react-helmet'
import HeroBanner from '../component/Common Banner Component/HeroBanner'
import ContactPageContactDetails from '../component/ContactPageContactDetails/ContactPageContactDetails'
import Contactform from '../component/Contact Form/Contactform'

const faqData = [
    {
      title:"What is the best way to contact Lee David Diamonds?",
      content:"The best way to connect with us is booking an appointment <a href='https://scheduler.zoom.us/lee-david/consultation' target='_blank' rel='noreferrer' style='textDecoration:none;cursor:pointer;color:black'>here</a>. Alternatively, you can use our website form, telephone (905) 467-7766, or email us at LeeDavidDiamonds@gmail.com "
    },
    {
      title:"How do I get a quote/price?",
      content:"A consultation is necessary to ensure we provide the most accurate and reliable quote."
    },
    {
      title:"Do you offer Lab-Grown Diamonds?",
      content:"We recognize that each client has unique preferences and values, which is why we offer both natural and lab-grown diamonds."
    },
    {
      title:"What materials are available at Lee David Diamonds?",
      content:"We offer a wide selection of natural and lab-grown diamonds, gemstones, gold, platinum, and more, all available for you to choose from."
    },
    {
      title:"Are your diamonds certified?",
      content:"Yes, all of our diamonds are certified by respected gemological laboratories, including the International Gemological Institute (IGI)."
    },
    {
      title:"Will my purchase come with an appraisal?",
      content:"Yes, with any purchase over $500, you’ll receive a luxury storage box and a third-party appraisal."
    },
    {
      title:"Do you do repair work?",
      content:"Repair services are available only for items purchased from Lee David Diamonds. For comprehensive repair needs, we recommend visiting your local jewelry store."
    },
    {
      title:"Do you have a social media presence?",
      content:"Yes, you can find us on Facebook and Instagram at @LeeDavidDiamonds."
    }
]

function Contact() {
    return (
        <>
          <Helmet>
            <title> 
            Contact Lee David Diamonds | Expert Guidance on Diamonds & Custom Jewelry
            </title>
            <meta 
            name='description' 
            content="Contact Lee David Diamonds for expert assistance in selecting the perfect diamond or custom jewelry. Explore our FAQ section for answers to your common jewelry questions." 
            />
          <link rel="canonical" href="https://www.leedaviddiamonds.com/contact" />

            </Helmet>
        
            <HeroBanner
              subHeading=""
              Heading="CONTACT US"
              nextLineSpanHeading=""
              customCodePara="We’re here to assist you with any questions or inquiries you may have about <span style='text-wrap:nowrap;'>Lee David Diamonds.</span> Reach out to us today, and let us help guide you through the perfect diamond selection or custom jewelry process."
              bgImg="/images/creatives/contact-hero-bg.jpg"
              btn={false}
              sideLinks={false}
              marginBottom={false}
            />

            <ContactPageContactDetails/>
    
            <Contactform
              sideImg="/images/creatives/contact-page-contact-form-img.png"
            />

            <FaqSection
               heading="Frequently Asked Questions"
               para="Browse our FAQ section, where we address the most common questions about our diamonds, services, and custom design process.\n Whether you're new to shopping for jewellery or have questions about working with a private jeweller, we’re here to provide clear, helpful answers to guide your journey with us."
               subHeading=""
               showImg={false}
               faqData={faqData}
            />

            <div style={{height:"30px"}}></div>
    
          <Footer
            newsletterSection={false}
          />
        </>
      )
}

export default Contact