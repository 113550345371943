import React from 'react'
import HeroBanner from '../component/Common Banner Component/HeroBanner'
import Footer from '../component/Footer/Footer'
import NewsList from '../component/NewsList/NewsList'
import { Helmet } from 'react-helmet'
import NewsListNew from '../component/NewsListNew/NewsListNew'

function Blog() {
    return (
        <div className="home-page">
          <Helmet>
            <title>
            Fine Jewellery Blog | Expert Tips, Trends & Behind-the-Scenes Insights
            </title>
            <meta
              name="description"
              content="Discover expert tips, trends, and behind-the-scenes stories from the world of fine jewelry. Stay updated on the latest styles, collections, and more!"
            ></meta>
          <link rel="canonical" href="https://www.leedaviddiamonds.com/blog" />
          </Helmet>
          <HeroBanner 
            subHeading=""
            Heading="BLOGS "
            spanHeading=''
            customCodePara="Welcome to our blog, where we share insights, trends, and stories from the world of fine jewellery. Explore expert tips, behind-the-scenes glimpses, and the latest updates from <span style='text-wrap:nowrap;'>Lee David Diamonds.</span>"
            nextLineSpanHeading=""
            bgImg="/images/creatives/blog-hero-bg.jpg"
            btn={false}
            btnRef="/"
            txtStart={false}
            sideLinks={false}
          />
          
   <NewsListNew/>

  

    <div style={{height:"30px"}}>

    </div>
    
          <Footer
            newsletterSection={false}
          />
          </div>
      )
}

export default Blog